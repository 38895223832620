// Import all plugins
import * as bootstrap from 'bootstrap'

// Or import only needed plugins
//import { Tooltip as Tooltip, Toast as Toast, Popover as Popover } from 'bootstrap';

// Or import just one
//import Alert as Alert from '../node_modules/bootstrap/js/dist/alert';

import {loadStripe} from '@stripe/stripe-js'

const stripe = await loadStripe('pk_test_QrZtkzywB9DBTxSkiHDqWWQm', {
    locale: 'es'
})

const appearance = {
    theme: 'stripe',
    variables: {
        colorBackground: '#fff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Poppins,Helvetica Neue,Helvetica,Arial,sans-serif,Roboto',
        spacialUnit: '2px',
        borderRadius: '4px'
    }
}

const elements = stripe.elements({appearance})

// Custom styling can be passed to options when creating an Element.
const style = {
    base: {
        color: '#30313d',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#87BBFD',
        },
    },
    invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
    }
  };
  
  // Create an instance of the card Element.
  const card = elements.create('card');
  
  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');

// Create a token or display an error when the form is submitted.
const form = document.getElementById('payment-form');
form.addEventListener('submit', async (event) => {
  document.getElementById('success').classList.add("hidden");
  document.getElementById('error').classList.add("hidden");

  let button = document.getElementById('make-payment')
  button.disable = true;
  button.innerHTML = 'Procesando...'

  event.preventDefault();

  const {token, error} = await stripe.createToken(card);

  if (error) {
    // Inform the customer that there was an error.
    const errorElement = document.getElementById('card-errors');
    errorElement.textContent = error.message;

    button.disable = false;
    button.innerHTML = 'Pagar'
  } else {
    // Send the token to your server.
    stripeTokenHandler(token, event);
  }
});

const stripeTokenHandler = async (token, event) => {
    const form = document.getElementById('payment-form');

    const myFormData = new FormData(event.target)

    const formDataObj = {}
    myFormData.forEach((value, key) => (formDataObj[key] = value))

    formDataObj['stripeToken'] = token
    formDataObj['name'] = formDataObj['buyer_email']

    const amount = parseFloat(formDataObj['amount'].replace(',', '')).toFixed(2).toString().replace('.', '')
    formDataObj['amount'] = amount

    const response = await fetch(form.action, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formDataObj),
    });
       
    console.log(response)

    let button = document.getElementById('make-payment')
    button.disable = false;
    button.innerHTML = 'Pagar'

    if(response.ok){
        card.clear()

        form.reset()

        document.getElementById('success').classList.remove("hidden");
        document.getElementById('error').classList.add("hidden");
    } else {
        document.getElementById('error').classList.remove("hidden");
        document.getElementById('success').classList.add("hidden");
    }
  }